
import { computed, defineComponent } from 'vue'
import Page from '@/components/Page.vue'
import Button from '@/components/Form/Button.vue'
import DataTable, { Column } from '@/components/DataTable/DataTable.vue'
import { useStore } from 'vuex'
import { IAccount } from '@/types'
import PageTop from '@/components/PageTop.vue'
import Edit from '@/components/DataTable/Edit.vue'
import { useRouter } from 'vue-router'
import { getItemsWithSamePropertyValue } from '@/globals/javascript/utils/helpers'
import { mixWB } from '@/globals/javascript/utils/mixins'

export default defineComponent({
  data(): { columns: Column[]} {
    return {
      columns: [
        {
          key: 'name',
          title: 'Name',
          canSort: true,
        },
        {
          key: 'users',
          title: 'Users',
          canSort: true,
        },
        {
          key: 'numberID',
          title: 'Nummer ID',
          canSort: true,
        },
        {
          key: 'canUseSampler',
          title: 'Sampler',
          canSort: true,
        },
        {
          key: 'canUseMapper',
          title: 'Mapper',
          canSort: true,
        },
        {
          key: 'isDemoModeActive',
          title: 'Demo mode',
          canSort: true,
        },
        {
          key: 'isActive',
          title: 'Status',
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
    }
  },
  components: {
    Page,
    Button,
    DataTable,
    PageTop,
    Edit,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { isAdmin } = store.getters

    const onAccountEditClick = (accountID: string) => {
      router.push({ name: 'ProAccount', params: { accountID } })
    }

    return {
      onAccountEditClick,
      isAdmin,
      rows: computed(() => store.getters.accounts.map((account: IAccount) => ({
        name: account.name,
        numberID: account?.numberID || 0,
        users: store.getters.usersByAccount(account.id).length,
        canUseSampler: account?.canUseSampler ? 1 : 0,
        canUseMapper: account?.canUseMapper ? 1 : 0,
        isDemoModeActive: account?.isDemoModeActive ? 1 : 0,
        isActive: account.isActive,
        id: account.id,
      }))),
      warnings: computed<string[]>(() => {
        const warnings: string[] = []
        const accountssWithDuplicateNumberIDs = getItemsWithSamePropertyValue(store.getters.accounts as IAccount[], 'numberID')
        accountssWithDuplicateNumberIDs.forEach((account) => {
          warnings.push(
            mixWB('%s konti benytter samme nummer ID (%s)',
              [account.length.toString(), account[0].numberID.toString()]),
          )
        })
        return warnings
      }),
    }
  },
})

