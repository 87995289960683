
import {
  ref, defineComponent,
  PropType,
  watch,
  computed,
} from 'vue'
import { IAccount } from '@/types'
import { copyObject } from '@/globals/javascript/utils/helpers'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import ProAccountPickUpPoint from '@/components/Modals/Dialogs/ProAccountPickUpPoint.vue'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import { mixWB } from '@/globals/javascript/utils/mixins'
import Edit from '@/components/DataTable/Edit.vue'
import { useRoute } from 'vue-router'

type TPickUpPoint = IAccount['pickUpPoints'][number]

export default defineComponent({
  props: {
    accountData: {
      type: Object as PropType<IAccount>,
      required: true,
    },
  },
  components: {
    Button, Dialog, DataTable, Edit,
  },
  setup(props, { emit }) {
    const route = useRoute()
    const isNew = computed(() => route.name === 'ProAccountNew')
    const isNewPickUpPointDialogShowing = ref(false)
    const pickUpPointToEdit = ref<IAccount['pickUpPoints'][number]>()
    const accountDataCopy = ref<IAccount>(copyObject(props.accountData))

    watch(accountDataCopy, () => {
      emit('fieldInput', accountDataCopy.value)
    }, {
      deep: true,
    })

    const onSubmit = () => {
      emit('onSubmit', accountDataCopy.value)
    }

    function onPickUpPointSubmit(
      { name: action, value: pickUpPoint }:{name: string, value:TPickUpPoint},
    ) {
      if (action === 'delete') {
        const index = accountDataCopy.value.pickUpPoints.findIndex(
          (p) => p === pickUpPointToEdit.value,
        )
        accountDataCopy.value.pickUpPoints.splice(index, 1)
        return
      }

      const pickUpPointFormattet = {
        placement: pickUpPoint.placement,
        address: {
          city: pickUpPoint.address.city,
          postalCode: pickUpPoint.address.postalCode,
          address: pickUpPoint.address.address,
        },
      }

      // Update existing
      if (action === 'update' && pickUpPointToEdit.value) {
        const index = accountDataCopy.value.pickUpPoints.findIndex(
          (p) => p === pickUpPointToEdit.value,
        )

        accountDataCopy.value.pickUpPoints[index] = {
          ...accountDataCopy.value.pickUpPoints[index],
          ...pickUpPointFormattet,
        }
        return
      }

      // Add new
      if (action === 'add-new') {
        accountDataCopy.value.pickUpPoints.push(
          pickUpPointFormattet,
        )
        return
      }
    }

    function onEditPickUpPoint(i: number) {
      pickUpPointToEdit.value = accountDataCopy.value.pickUpPoints[i]
      isNewPickUpPointDialogShowing.value = true
      return
    }

    function onAddNewPickUpPoint() {
      pickUpPointToEdit.value = undefined
      isNewPickUpPointDialogShowing.value = true
    }

    const rows = computed<Row[]>(() => props.accountData.pickUpPoints.map((p, i) => ({
      placement: p.placement ? p.placement : '-',
      address: p.address.address,
      city: p.address.city,
      postalCode: p.address.postalCode,
      index: i,
    })))

    const columns = [
      {
        key: 'address',
        title: mixWB('W: Adresse'),
        canSort: true,
      },
      {
        key: 'postalCode',
        title: mixWB('W: Postnummer'),
        canSort: true,
      },
      {
        key: 'city',
        title: mixWB('W: By'),
        canSort: true,
      },
      {
        key: 'placement',
        title: mixWB('W: Placering'),
        canSort: true,
      },
    ]
    if (!isNew.value) {
      columns.push({
        key: 'edit',
        title: '',
        canSort: false,
      })
    }

    return {
      rows,
      columns,
      ProAccountPickUpPoint,
      onSubmit,
      accountDataCopy,
      isNewPickUpPointDialogShowing,
      onPickUpPointSubmit,
      onEditPickUpPoint,
      pickUpPointToEdit,
      Edit,
      isNew,
      onAddNewPickUpPoint,
    }
  },
})

