import { ILogV3 } from './resources-version-3'

export interface IPriceItem {
  isActive: boolean,
  value: number,
  type: 'price' | 'multiplier'
  logs: ILogV3[]
}
export interface ITrialsAndDiscountsItem {
  value: number,
  type: 'counter'
  logs: ILogV3[]
}
export interface IPricesProItem {
  translation: string
  id: string
  other: {
    [key: string]:IPriceItem
  }
  matAnalyses: {
    [key: string]:IPriceItem
  }
  analysisDeliveryTime: {
    [key: string]:IPriceItem
  }
  sampleHandOver: {
    [key: string]:IPriceItem
  }
}
export interface IPricesLabsItem {
  translation: string
  id: string
  matAnalyses: {
    [key: string]:IPriceItem
  }
  analysisDeliveryTime: {
    [key: string]:IPriceItem
  }
  sampleHandOver: {
    [key: string]:IPriceItem
  }
}

export type UPricesItem = IPricesLabsItem | IPricesProItem
