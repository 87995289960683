
import { useStore } from 'vuex'
import {
  computed, ref, defineComponent,
  PropType,
  watch,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import Toggle from '@/components/Form/Toggle.vue'
import { IAccount } from '@/types'
import Select from '@/components/Form/Select.vue'
import { mixWB } from '@/globals/javascript/utils/mixins'
import {
  IAnalysisDeliveryTimeOptionV3, ILaboratoryV3,
} from '@/types/resources-version-3'
import { copyObject } from '@/globals/javascript/utils/helpers'

export default defineComponent({
  props: {
    accountData: {
      type: Object as PropType<IAccount>,
      required: true,
    },
  },
  components: {
    InputField,
    Toggle,
    Select,
  },
  setup(props, { emit }) {
    const store = useStore()
    const { isAdmin } = store.getters
    const accountDataCopy = ref<IAccount>(copyObject(props.accountData))
    watch(accountDataCopy, () => {
      emit('fieldInput', accountDataCopy.value)
    }, {
      deep: true,
    })

    const onAllowMilvaEuroFinsUpdate = (value: boolean) => {
      // Check to reset primaryLabID
      if (!value && accountDataCopy.value.primaryLabID === 'milvaEuroFins') {
        accountDataCopy.value.primaryLabID = 'euroFins'
      }

      // Check to reset billPerRequisition
      if (!value && accountDataCopy.value.billPerRequisition) {
        accountDataCopy.value.billPerRequisition = false
      }
    }

    const onSubmit = () => {
      emit('onSubmit', accountDataCopy.value)
    }

    return {
      isAdmin,
      labOptions: computed(() => {
        const options: { key: string, value: string }[] = []

        // Add lab options from DB
        store.getters.laboratoriesAsArrayV3.forEach((lab: ILaboratoryV3) => {
          options.push({
            key: lab.id,
            value: mixWB(lab.translation),
          })
        })

        // Add milva EuroFins Option
        if (accountDataCopy.value.allowMilvaEuroFins) {
          options.unshift({
            key: 'milvaEuroFins',
            value: 'Milva (EuroFins)',
          })
        }

        return options
      }),
      analysisDeliveryTimeOptions: store.getters.analysisDeliveryTimeOptionsAsArrayV2.map(
        (x: IAnalysisDeliveryTimeOptionV3) => ({
          key: x.id,
          value: x.days === 1
            ? mixWB('1_DAY_X_O_CLOCK', [x.hours.toString()])
            : mixWB('X_DAYS_X_O_CLOCK', [x.days.toString(), x.hours.toString()]),
        }),
      ),
      onAllowMilvaEuroFinsUpdate,
      onSubmit,
      accountDataCopy,
    }
  },
})

