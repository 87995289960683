
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {
  computed, onMounted, ref, defineComponent,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import Select from '@/components/Form/Select.vue'
import Toggle from '@/components/Form/Toggle.vue'
import CheckboxGroup from '@/components/Form/CheckboxGroup.vue'
import {
  IAccount, TUser, IUserBase, IUserProData, Role, RoleHub, IUserHubData,
} from '@/types'
import { isHubUser, isProUser } from '@/globals/javascript/utils/typeGuards'
import Box from '@/components/Box.vue'

export default defineComponent({
  components: {
    InputField,
    Box,
    Button,
    Toggle,
    Select,
    CheckboxGroup,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const data = ref<{
      base: Omit<IUserBase, 'firstTimeLogin' | 'createdAt'>,
      pro?: Omit<IUserProData, 'isActivated'|'nextProjectNumberID'|'activatedAt'|'lastSeen'|'lastSeenV3'|'lastSeen' | 'lastSeenV3' | 'nextPersonalSamplingID'>,
      hub?: Omit<IUserHubData, 'isActivated'|'activatedAt'| 'projects'>
    }>()
    const isLoading = ref(true)
    const successMessage = ref(false)
    const failMessage = ref<string>()
    const checkboxAdmin = ref<string[]>([])
    const availableRolesHub = [
      {
        key: RoleHub.SUPER_USER,
        label: 'Super user',
      },
    ]
    const availableRolesPro = [
      {
        key: Role.SUPER_USER,
        label: 'Super user',
      },
      {
        key: Role.SCREENER,
        label: 'Screener',
      },
    ]

    onMounted(() => {
      const user = store.getters.users.find(
        (user: TUser) => user.id === route.params.userID,
      )
      data.value = {
        base: {
          id: '',
          numberID: 0,
          fullName: '',
          initials: '',
          email: '',
          phoneNumber: '',
          mustUpdatePassword: true,
          isAdmin: false,
        },
      }

      data.value.base = {
        id: user.id,
        numberID: user.numberID,
        fullName: user.fullName,
        initials: user.initials,
        email: user.email,
        phoneNumber: user.phoneNumber,
        mustUpdatePassword: user.mustUpdatePassword,
        isAdmin: user.isAdmin,
      }
      if (isProUser(user)) {
        data.value.pro = {
          isActive: user.isActive,
          accountID: user.accountID,
          roles: user.roles,
          expiryDate: user.expiryDate,
        }
      }
      if (isHubUser(user)) {
        data.value.hub = {
          lastSeen: '',
          isActive: user.hub.isActive,
          accountID: user.hub.accountID,
          roles: user.hub.roles,
        }
      }
      if (user.isAdmin) {
        checkboxAdmin.value.push('admin')
      }

      isLoading.value = false
    })

    function toggleAdminRole() {
      if (!data.value) {
        return
      }
      if (checkboxAdmin.value.includes('admin')) {
        checkboxAdmin.value = []
        data.value.base.isAdmin = false
        return
      }
      checkboxAdmin.value = ['admin']
      data.value.base.isAdmin = true
    }

    const isOnlyMilvaSampler = computed(() => {
      const user = store.getters.users.find(
        (user: TUser) => user.id === route.params.userID,
      )
      // Only Pro user without account
      return isProUser(user) && !data.value?.pro?.accountID && !isHubUser(user)
    })

    const onSubmit = () => {
      if (isLoading.value) {
        return
      }
      failMessage.value = undefined
      isLoading.value = true

      store.dispatch('updateUser', {
        data: {
          userID: route.params.userID,
          userData: data.value?.base,
          hubData: data.value?.hub,
          proData: data.value?.pro,
        },
      })
        .then(() => {
          isLoading.value = false
          successMessage.value = true
          setTimeout(() => {
            successMessage.value = false
          }, 1000)
        })
        .catch((error) => {
          failMessage.value = error.message
          isLoading.value = false
        })
    }

    return {
      proAccounts: computed<{ key: string; value: string }[]>(() => store.getters.accounts.map(
        (account: IAccount) => ({ key: account.id, value: account.name }),
      )),
      hubAccounts: computed<{ key: string; value: string }[]>(() => store.getters['hubStore/accounts'].map(
        (account: IAccount) => ({ key: account.id, value: account.name }),
      )),
      availableRolesHub,
      availableRolesPro,
      onSubmit,
      data,
      isLoading,
      isOnlyMilvaSampler,
      successMessage,
      failMessage,
      route,
      checkboxAdmin,
      toggleAdminRole,
    }
  },
})

