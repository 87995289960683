
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  computed, onMounted, ref, defineComponent,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import Select from '@/components/Form/Select.vue'
import Toggle from '@/components/Form/Toggle.vue'
import CheckboxGroup from '@/components/Form/CheckboxGroup.vue'
import {
  IAccount, TUser, IUserBase, IUserProData, Role,
} from '@/types'
import { isProUser } from '@/globals/javascript/utils/typeGuards'

export default defineComponent({
  components: {
    InputField,
    Button,
    Toggle,
    Select,
    CheckboxGroup,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(true)
    const successMessage = ref(false)
    const failMessage = ref<string>()
    const checkboxAdmin = ref<string[]>([])
    const data = ref<{
      userID: string,
      base: Omit<IUserBase, 'createdAt'|'numberID'|'firstTimeLogin'>,
      pro: Omit<IUserProData, 'isActivated'|'activatedAt'|'nextProjectNumberID'|'lastSeen' | 'lastSeenV3' | 'nextPersonalSamplingID'>
    }>({
      userID: '',
      base: {
        id: '',
        fullName: '',
        initials: '',
        email: '',
        phoneNumber: '',
        mustUpdatePassword: true,
        isAdmin: false,
      },
      pro: {
        isActive: true,
        accountID: '',
        roles: [],
        expiryDate: '',
      },
    })

    const existingUserOptions = computed<{ key: string; value: string }[]>(
      () => store.getters.hubUsers
        .reduce(
          (prev: { key: string, value: string }[], user: TUser) => {
          // skip if already pro user
            if (!isProUser(user)) {
              prev.push({ key: user.id, value: user.email })
            }
            return prev
          }, [{ key: '', value: '-' }],
        ),
    )

    const availableRoles = [
      {
        key: Role.SUPER_USER,
        label: 'Super user',
      },
      {
        key: Role.SCREENER,
        label: 'Screener',
      },
    ]

    function toggleAdminRole() {
      if (!data.value) {
        return
      }
      if (checkboxAdmin.value.includes('admin')) {
        checkboxAdmin.value = []
        data.value.base.isAdmin = false
        return
      }
      checkboxAdmin.value = ['admin']
      data.value.base.isAdmin = true
    }

    const accounts = computed<{ key: string; value: string }[]>(() => store.getters.accounts.map(
      (account: IAccount) => ({ key: account.id, value: account.name }),
    ))

    onMounted(() => {
      const preSelectedUser = route.params.userID && store.getters.users.find(
        (user: TUser) => user.id === route.params.userID,
      )

      data.value.userID = preSelectedUser?.id ?? ''
      isLoading.value = false
    })

    const onSubmit = () => {
      if (isLoading.value) {
        return
      }
      failMessage.value = undefined
      isLoading.value = true

      store.dispatch('createProUser', {
        data: {
          userID: data.value?.userID,
          userData: data.value?.base,
          proData: data.value?.pro,
        },
      })
        .then(({ data }) => {
          isLoading.value = false
          successMessage.value = true
          setTimeout(() => {
            router.push({ name: 'User', params: { userID: data.userID } })
          }, 1000)
        })
        .catch((error) => {
          failMessage.value = error.message
          isLoading.value = false
        })
    }

    return {
      checkboxAdmin,
      accounts,
      existingUserOptions,
      availableRoles,
      onSubmit,
      data,
      isLoading,
      successMessage,
      failMessage,
      toggleAdminRole,
    }
  },
})

