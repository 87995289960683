
import { useStore } from 'vuex'
import {
  ref, defineComponent,
  PropType,
  watch,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import ImageUpload from '@/components/Form/ImageUpload.vue'
import { IAccount } from '@/types'
import Select from '@/components/Form/Select.vue'
import { ICountry } from '@/types/countries'
import { mixWB } from '@/globals/javascript/utils/mixins'
import { copyObject } from '@/globals/javascript/utils/helpers'

export default defineComponent({
  props: {
    accountData: {
      type: Object as PropType<IAccount>,
      required: true,
    },
  },
  components: {
    InputField,
    ImageUpload,
    Select,
  },
  setup(props, { emit }) {
    const store = useStore()
    const { isAdmin } = store.getters
    const accountDataCopy = ref<IAccount>(copyObject(props.accountData))
    watch(accountDataCopy, () => {
      emit('fieldInput', accountDataCopy.value)
    }, {
      deep: true,
    })

    const onSubmit = () => {
      emit('onSubmit', accountDataCopy.value)
    }

    return {
      isAdmin,
      countryOptions: store.getters.allActiveCountries.map((x: ICountry) => ({
        key: x.countryCode,
        value: mixWB(x.translation),
      })),
      onSubmit,
      accountDataCopy,
    }
  },
})

